import {cssVal, getDataByCodePostal, getDataBySiren, newInput, texteForSiege, tryToDate} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const tup = {
    title: "TUP Dissolution sans liquidation",
    texte: "<strong>{{societeGroup.societeDenomination}} [[({{societeGroup.sigle}})]]</strong> {{societyGroup.societeFormeJuridique}}, au capital de {{societyGroup.societeCapital}} €, Siège : {{siegeSocialGroup.societeAdresse}} [[{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}, RCS : {{societyGroup.societeRcsVille}} N°{{societyGroup.societeRcs}}. Suivant décision en date du {{acteGroup.dateDu}} l'associé unique, la société {{associeUniqueGroup.denomination}} [[({{associeUniqueGroup.sigle}})]], RCS {{associeUniqueGroup.lieuImmatriculation}} {{associeUniqueGroup.rcs}}. Siège: {{associeUniqueGroup}}, a décidé la dissolution sans liquidation de la société {{societyGroup.denomination}}, par application de l'article 1844-5 al.3 du Code Civil. Cette dissolution entraîne la transmission universelle du patrimoine de la société {{societyGroup.denomination}} à la société {{associeUniqueGroup.denomination}}, à l'issue du délai d'opposition qui est de 30 jours à compter de cette publication. La société {{societyGroup.denomination}} sera radiée au RCS {{societyGroup.soceteRcsVille}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "associeUniqueGroup":
                tmpText = texteForSiege(varText, 'associeUniqueGroup');
                break;
            case "acteGroup.dateDu":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        societyGroup: [{}],
        siegeSocialGroup: [{}],
        associeUniqueGroup: [{}],
    },
    schema: [
        {
            //0
            name: "societyGroup",
            label: "La société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.rcs, '_required', {name: 'societeRcs',
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            tup.model.societyGroup[0] = data.values.societe;
                            tup.model.siegeSocialGroup[0] = data.values.siegeSocial;

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            tup.schema[1].children[3].options = dataCP.options;
                            tup.model.siegeSocialGroup[0].societeVille = dataCP.ville_slug;
                        }
                    }
                }),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
            ]
        },
        {
            //1
            name: "siegeSocialGroup",
            label: "Siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if (Object.keys(data).length === 0) {
                                return false;
                            }

                            tup.schema[1].children[3].options = data.options;
                            tup.model.siegeSocialGroup[0].ville = data.ville_slug;
                            tup.model.societyGroup[0].lieuImmatriculation = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //2
            name: "acteGroup",
            label: "Acte",
            type: "group",
            value: [{}],
            children: [
                newInput(def.dateDu, '_required'),
            ]
        },
        {
            //3
            name: "associeUniqueGroup",
            label: "L'associé unique",
            type: "group",
            value: [{}],
            children: [
                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            tup.model.associeUniqueGroup[0] = Object.assign(tup.model.associeUniqueGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            tup.schema[3].children[8].options = dataCP.options;
                            tup.model.associeUniqueGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }),
                newInput(def.lieuImmatriculation, '_required'),
                newInput(def.denomination, '_required'),
                newInput(def.sigle, '_required'),
                newInput(def.formesSocietes, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if (Object.keys(data).length === 0) {
                                return false;
                            }

                            tup.schema[3].children[8].options = data.options;
                            tup.model.associeUniqueGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_required'),
            ]
        },
    ]
}